import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Madison({ styles, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-container">
        <Elements.Heading
          className="formkit-name formkit-heading"
          name="name"
          defaults={{ content: "Company name" }}
        />
        <Elements.Image name="image" size={{ h: 1050, w: 1100 }} />

        <div className="formkit-cards">
          <div
            className="formkit-card"
            style={styles.forElement("content_card")}
          >
            <Elements.Heading
              className="formkit-header formkit-heading"
              name="header"
              tag={"h2"}
              defaults={{ content: "Add a title here" }}
            />
            <Elements.Region name="content" className="formkit-content">
              <Elements.Content
                defaults={{
                  content: "Provide some more detail in this subtitle"
                }}
              />
            </Elements.Region>
          </div>
          <div className="formkit-card" style={styles.forElement("form_card")}>
            <Elements.Heading
              className="formkit-form-header formkit-heading"
              name="form_header"
              tag={"h2"}
              defaults={{ content: "Join now" }}
            />
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Sign up" }}
                />
              </Elements.CustomFields>
              <Elements.Content
                name="disclaimer"
                defaults={{
                  content: "We respect your privacy. Unsubscribe at any time."
                }}
              />
            </Elements.Form>
          </div>
        </div>
      </div>
      <Elements.BuiltWith background="page" />
    </Elements.LandingPage>
  )
}

Madison.style = "landing_page"
Madison.categories = ["Product", "Newsletter"]
Madison.thumbnail = ""
Madison.preview = "https://demo.ck.page/madison"
Madison.fields = [
  {
    name: "first_name",
    label: "Your first name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Madison, { name: "Madison" })
